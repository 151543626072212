import dayjs from 'dayjs'

import 'dayjs/locale/zh-tw'

dayjs.extend(require('dayjs/plugin/isSameOrAfter'))
dayjs.extend(require('dayjs/plugin/isSameOrBefore'))

dayjs.locale('zh-tw')

export default (context, inject) => {
  context.$dayjs = dayjs
  inject('dayjs', dayjs)
}
